@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

@font-face {
  font-family: 'Poppins';
  src: url('/assets/font/Poppins-Regular.otf') format('opentype');
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
body {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px;
  padding: 0 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a5a76;
}


  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-input-number {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    width: 100%;
    height: 36px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #eef4f9;
    background-image: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    width: 90px;
    margin: 0;
    padding: 0;
    border: 1px solid #3EAC7B;
    border-radius: 4px;
  }
  .ant-input-number::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
  }
  .ant-input-number:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-number::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-number:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number:hover {
    border-color: #3EAC7B;
    border-right-width: 1px !important;
  }
  .ant-input-number:focus {
    border-color: #3EAC7B;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  textarea.ant-input-number {
    max-width: 100%;
    height: auto;
    min-height: 36px;
    line-height: 1.5;
    vertical-align: bottom;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  }
  .ant-input-number-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
  }
  .ant-input-number-sm {
    height: 24px;
    padding: 1px 7px;
  }
  .ant-input-number-handler {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    line-height: 0;
    text-align: center;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
  .ant-input-number-handler:active {
    background: #f4f4f4;
  }
  .ant-input-number:hover {
    border-color: #3EAC7B;
    border-right-width: 1px !important;
  }
  .ant-input-number-focused {
    border-color: #3EAC7B;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number-disabled .ant-input-number-input {
    cursor: not-allowed;
  }
  .ant-input-number-disabled .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 36px;
    padding: 0 11px;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    outline: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    -moz-appearance: textfield !important;
    color: #4C4E58;
    font-size: 16px;
  }
  .ant-input-number-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
  }
  .ant-input-number-input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-number-input::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-number-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number-input[type='number']::-webkit-inner-spin-button,
  .ant-input-number-input[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  .ant-input-number-lg {
    padding: 0;
    font-size: 16px;
  }
  .ant-input-number-lg input {
    height: 38px;
  }
  .ant-input-number-sm {
    padding: 0;
  }
  .ant-input-number-sm input {
    height: 22px;
    padding: 0 7px;
  }
  .ant-input-number-handler-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 100%;
    background: #fff;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    -webkit-transition: opacity 0.24s linear 0.1s;
    transition: opacity 0.24s linear 0.1s;
  }
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    display: inline-block;
    font-size: 12px;
    font-size: 7px \9;
    -webkit-transform: scale(0.58333333) rotate(0deg);
        -ms-transform: scale(0.58333333) rotate(0deg);
            transform: scale(0.58333333) rotate(0deg);
    min-width: auto;
    margin-right: 0;
  }
  :root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
  :root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    font-size: 12px;
  }


  